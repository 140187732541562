import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "./AdminEditCompanies.css";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";

const AdminEditCompanies = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [companyData, setCompanyData] = useState({
    name: "",
    email: "",
    address: "",
    contact: "",
    companyExtension: "company",
    gstNumbers: [""],
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const fetchCompanyData = async () => {
      try {
        const token = localStorage.getItem("token");
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const config = {
          method: "get",
          url: `${baseUrl}/api/company/${id}`,
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios(config);
        setCompanyData(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    fetchCompanyData();
  }, [id]);

  const handleChange = (e) => {
    setCompanyData({
      ...companyData,
      [e.target.name]: e.target.value,
    });
  };

  const handleGstChange = (e, index) => {
    const { value } = e.target;
    const updatedGstNumbers = [...companyData.gstNumbers];
    updatedGstNumbers[index] = value;
    setCompanyData({ ...companyData, gstNumbers: updatedGstNumbers });
  };

  const handleAddGstNumber = () => {
    setCompanyData({
      ...companyData,
      gstNumbers: [...companyData.gstNumbers, ""],
    });
  };

  const handleRemoveGstNumber = (index) => {
    const updatedGstNumbers = [...companyData.gstNumbers];
    updatedGstNumbers.splice(index, 1);
    setCompanyData({ ...companyData, gstNumbers: updatedGstNumbers });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = companyData.name ? "" : "This field is required.";
    tempErrors.email = companyData.email ? "" : "This field is required.";
    if (companyData.email) {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      tempErrors.email = emailPattern.test(companyData.email)
        ? ""
        : "Email is not valid.";
    }
    tempErrors.address = companyData.address ? "" : "This field is required.";
    tempErrors.contact = companyData.contact ? "" : "This field is required.";
    if (companyData.contact) {
      const contactPattern = /^[0-9]{10}$/;
      tempErrors.contact = contactPattern.test(companyData.contact)
        ? ""
        : "Contact number is not valid.";
    }
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSave = async () => {
    if (validate()) {
      try {
        const token = localStorage.getItem("token");
        const baseUrl = process.env.REACT_APP_BASE_URL;
        const config = {
          method: "put",
          url: `${baseUrl}/api/company/${id}`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data: JSON.stringify(companyData),
        };

        const response = await axios(config);
        navigate("/companies");
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleCancel = () => {
    navigate("/companies");
  };

  return (
    <div className="edit-company-container">
      <h2>Edit Company</h2>

      <label>Company Name</label>
      <input
        type="text"
        name="name"
        value={companyData.name}
        onChange={handleChange}
      />
      {errors.name && <span className="error">{errors.name}</span>}

      <label>Email ID:</label>
      <input
        type="text"
        name="email"
        value={companyData.email}
        onChange={handleChange}
      />
      {errors.email && <span className="error">{errors.email}</span>}

      <label>Address</label>
      <input
        type="text"
        name="address"
        value={companyData.address}
        onChange={handleChange}
      />
      {errors.address && <span className="error">{errors.address}</span>}

      <label>Contact Number</label>
      <input
        type="text"
        name="contact"
        value={companyData.contact}
        onChange={handleChange}
      />
      {errors.contact && <span className="error">{errors.contact}</span>}

      <label>Company Extension</label>
      <input
        type="text"
        name="companyExtension"
        value={companyData.companyExtension}
        onChange={handleChange}
      />

      <label>GST Numbers</label>
      {companyData.gstNumbers.map((gstNumber, index) => (
        <div key={index} className="gst-number-row">
          <input
            type="text"
            name="gstNumbers"
            value={gstNumber}
            onChange={(e) => handleGstChange(e, index)}
          />
          {companyData.gstNumbers.length > 1 && (
            <AiOutlineMinusCircle
              className="icon"
              onClick={() => handleRemoveGstNumber(index)}
            />
          )}
          {index === companyData.gstNumbers.length - 1 && (
            <AiOutlinePlusCircle
              className="icon"
              onClick={handleAddGstNumber}
            />
          )}
        </div>
      ))}

      <div className="edit-company-buttons">
        <button className="save" onClick={handleSave}>
          Save
        </button>
        <button className="cancel" onClick={handleCancel}>
          Cancel
        </button>
      </div>
    </div>
  );
};

export default AdminEditCompanies;
