import React, { useState, useEffect } from "react";
import Button from "../../../Components/Button/Button";
import { useCart } from "../../../contexts/CartContext";
import axios from "axios";
import {jwtDecode} from "jwt-decode";
import "./B2EShipping.css";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const B2EShipping = () => {
  const { cart, clearCart } = useCart();
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const nav = useNavigate();
  const [companyId, setCompanyId] = useState(null);
  const [form, setForm] = useState({
    name: "",
    email: "",
    number: "",
    billingAddress: "",
    shippingAddress: "",
    gstNumber: "",
    sameAsShipping: false,
  });
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);

  const getItemPrice = (item) => {
    if (item.priceByCompany && Array.isArray(item.priceByCompany)) {
      const companyPriceObj = item.priceByCompany.find(
        (priceObj) => priceObj.companyId === companyId
      );
      if (companyPriceObj) {
        return Number(companyPriceObj.price);
      }
    }
    return Number(item.companyPrice || 0);
  };

  useEffect(() => {
    const companyToken = localStorage.getItem("companyToken");
    if (companyToken) {
      const decodedToken = jwtDecode(companyToken);
      setCompanyId(decodedToken.id);
    }
  }, []);

  useEffect(() => {
    fetchCompanyDetails();
  }, []);

  useEffect(() => {
    const calculatedSubtotal = cart.reduce(
      (acc, item) => acc + getItemPrice(item) * item.quantity,
      0
    );
    setSubtotal(calculatedSubtotal);
    setTotal(calculatedSubtotal);
  }, [cart, companyId]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setForm({
      ...form,
      [name]: type === "checkbox" ? checked : value,
      ...(name === "sameAsShipping" && { shippingAddress: form.billingAddress }),
    });
  };

  const fetchCompanyDetails = async () => {
    const token = localStorage.getItem("companyToken");
    try {
      const response = await axios.get(`${baseUrl}/api/company/details`, {
        headers: { Authorization: token },
      });
      const companyData = response.data;
      setForm({
        name: companyData.name || "",
        email: companyData.email || "",
        number: companyData.contact || "",
        billingAddress: companyData.address || "",
        shippingAddress: "",
        gstNumber: companyData.gstNumbers[0] || "",
        sameAsShipping: false,
        gstNumbers: companyData.gstNumbers,
      });
    } catch (error) {
      toast.error("Please login with company email to continue");
      nav("/b2e-login");
      console.error(error.response?.data?.message || "Failed to fetch details");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!form.name || !form.email || !form.number || !form.billingAddress || !form.shippingAddress) {
      toast.error("Please enter your details");
      return;
    }

    const token = localStorage.getItem("companyToken");

    if (!token) {
      toast.error("Authentication token is missing. Please log in again.");
      return;
    }

    const isTokenValid = await validateToken(token);
    if (!isTokenValid) {
      toast.error("Invalid or expired token. Please log in again.");
      nav("/login");
      return;
    }

  
    try {
      const razorpayResponse = await axios.post(`${baseUrl}/api/order/initiate`, {
        total: total * 100,
        currency: "INR",
      });
  
      const { razorpayOrderId, amount, currency } = razorpayResponse.data;
  
      const options = {
        key: "rzp_live_arj0ffgOzVbLQB",
        amount,
        currency,
        order_id: razorpayOrderId,
        name: form.name,
        description: "Order Payment",
        handler: async (response) => {
          try {
            const orderData = {
              ...form,
              total,
              cart: cart.map((item) => ({
                productId: item._id,
                name: item.name,
                quantity: item.quantity,
                price: item.standardPrice,
                additionalInfo: item.additionalInfo,
                sku: item.sku,
              })),
              razorpay_order_id: response.razorpay_order_id,
              razorpay_payment_id: response.razorpay_payment_id,
              razorpay_signature: response.razorpay_signature,
            };
  
            const token = localStorage.getItem("companyToken");
            const saveOrderResponse = await axios.post(`${baseUrl}/api/company-order`, orderData, {
              headers: {
                Authorization: `${token}`,
                "Content-Type": "application/json",
              },
            });
  
            toast.success("Order placed successfully!");
            clearCart();
            nav("/b2e-home-page");
          } catch (error) {
            toast.error("Error saving order. Please contact support.");
            console.error("Error saving order:", error);
          }
        },
        prefill: {
          name: form.name,
          email: form.email,
          contact: form.number,
        },
      };
  
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
  
      paymentObject.on("payment.failed", (response) => {
        console.error("Payment failed:", response.error);
        toast.error("Payment failed. Please try again.");
      });
    } catch (error) {
      toast.error("Error initiating payment");
      console.error("Error initiating Razorpay payment:", error);
    }
  };

  const validateToken = async (token) => {
    try {
      const response = await axios.post(`${baseUrl}/api/company-order/validate-token`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
      return response.data.valid;
    } catch (error) {
      console.error("Token validation failed:", error);
      return false;
    }
  };
  
  
  return (
    <>
      <ToastContainer />
      <div className="shipping-container">
        <div className="shipping-form-container">
          <p>Shipping Address</p>
          <form>
            <table>
              <tr>
                <td>
                  <label for="name">Name:</label>
                </td>
                <td className="input-field">
                  <input
                    type="text"
                    id="name"
                    name="name"
                    value={form.name}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="email">Email:</label>
                </td>
                <td className="input-field">
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={form.email}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="phone">Phone Number:</label>
                </td>
                <td className="input-field">
                  <input
                    type="tel"
                    id="number"
                    name="number"
                    value={form.number}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <td>
                  <label for="billingAddress">Billing Address:</label>
                </td>
                <td className="input-field">
                  <textarea
                    id="billingAddress"
                    name="billingAddress"
                    value={form.billingAddress}
                    onChange={handleChange}
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label>
                    Billing address same as shipping address.
                    <input
                      type="checkbox"
                      id="sameAsShipping"
                      name="sameAsShipping"
                      style={{ width: "13vw" }}
                      checked={form.sameAsShipping}
                      onChange={handleChange}
                    />
                  </label>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="shippingAddress">Shipping Address:</label>
                </td>
                <td className="input-field">
                  <textarea
                    id="shippingAddress"
                    name="shippingAddress"
                    value={form.sameAsShipping ? form.billingAddress : ""}
                    onChange={handleChange}
                    disabled={form.sameAsShipping}
                  ></textarea>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="gstDropdown">GST Number:</label>
                </td>
                <td className="input-field">
                  <select
                    id="gstDropdown"
                    name="gstDropdown"
                    value={form.gstNumber}
                    onChange={handleChange}
                  >
                    {form?.gstNumbers?.map((gst, index) => (
                      <option key={index} value={gst}>
                        {gst}
                      </option>
                    ))}
                  </select>
                </td>
              </tr>
            </table>
          </form>
          <div className="shipping-button">
            <Button label="Buy Now" color="#73A9FA" action={handleSubmit} />
          </div>
        </div>
        <div className="shipping-summary">
          <div className="shipping-summary-container">
            <div className="table-container">
              <table className="table">
                <tbody>
                  <tr>
                    <td>Subtotal:</td>
                    <td>₹{subtotal}</td>
                  </tr>
                  {/* <tr>
                    <td>Shipping:</td>
                    <td>₹{shipping}</td>
                  </tr> */}
                </tbody>
              </table>
              <div className="horizontal-line"></div>
              <table className="table">
                <tbody>
                  <tr>
                    <td>Your Total:</td>
                    <td>₹{total}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default B2EShipping;
